<template>
  <div class="tinymce-box">
    <Editor v-model="contentValue"
            :init="init"
            :disabled="disabled"
            @onClick="onClick" />
  </div>
</template>

<script>
// import api from '../api/api.js'

//引入tinymce编辑器
import Editor from '@tinymce/tinymce-vue'

//引入方式引入node_modules里的tinymce相关文件文件
import tinymce from 'tinymce/tinymce' //tinymce默认hidden，不引入则不显示编辑器
import 'tinymce/themes/silver'  //编辑器主题，不引入则报错
import 'tinymce/icons/default'  //引入编辑器图标icon，不引入则不显示对应图标

// 引入编辑器插件
import 'tinymce/plugins/advlist'  //高级列表
import 'tinymce/plugins/anchor'  //锚点
import 'tinymce/plugins/autolink'  //自动链接
import 'tinymce/plugins/autoresize'  //编辑器高度自适应,注：plugins里引入此插件时，Init里设置的height将失效
import 'tinymce/plugins/autosave'  //自动存稿
import 'tinymce/plugins/charmap'  //特殊字符
import 'tinymce/plugins/code'  //编辑源码
import 'tinymce/plugins/codesample'  //代码示例
import 'tinymce/plugins/directionality'  //文字方向
import 'tinymce/plugins/emoticons'  //表情
import 'tinymce/plugins/fullpage'  //文档属性
import 'tinymce/plugins/fullscreen'  //全屏
import 'tinymce/plugins/help'  //帮助
import 'tinymce/plugins/hr'  //水平分割线
import 'tinymce/plugins/image'  //插入编辑图片
import 'tinymce/plugins/importcss'  //引入css
import 'tinymce/plugins/insertdatetime'  //插入日期时间
import 'tinymce/plugins/link'  //超链接
import 'tinymce/plugins/lists' //列表插件
import 'tinymce/plugins/media' //插入编辑媒体
import 'tinymce/plugins/nonbreaking' //插入不间断空格
import 'tinymce/plugins/pagebreak' //插入分页符
import 'tinymce/plugins/paste' //粘贴插件
import 'tinymce/plugins/preview'//预览
import 'tinymce/plugins/print'//打印
import 'tinymce/plugins/quickbars'  //快速工具栏
import 'tinymce/plugins/save'  //保存
import 'tinymce/plugins/searchreplace'  //查找替换
// import 'tinymce/plugins/spellchecker'  //拼写检查，暂未加入汉化，不建议使用
import 'tinymce/plugins/tabfocus'  //切入切出，按tab键切出编辑器，切入页面其他输入框中
import 'tinymce/plugins/table'  //表格
import 'tinymce/plugins/template'  //内容模板
import 'tinymce/plugins/textcolor'  //文字颜色
import 'tinymce/plugins/textpattern'  //快速排版
import 'tinymce/plugins/toc'  //目录生成器
import 'tinymce/plugins/visualblocks'  //显示元素范围
import 'tinymce/plugins/visualchars'  //显示不可见字符
import 'tinymce/plugins/wordcount'  //字数统计
import '../assets/js/langs/zh_CN'

export default {
  name: 'TEditor',
  components: {
    Editor
  },
  props: {
    value: {
      type: String,
      default: '请输入'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    plugins: {
      type: [String, Array],
      default: 'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern autosave '
    },
    toolbar: {
      type: [String, Array],
      default: 'fullscreen undo redo restoredraft |image | preview | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | \
                styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
                table image media charmap emoticons hr pagebreak insertdatetime print  | code selectall | indent2em lineheight formatpainter axupimgs'
    },
  },
  data () {
    return {
      init: {
        image_dimensions: false,
        selector: 'textarea',  // change this value according to your HTML
        automatic_uploads: true,
        language: 'zh_CN',  //语言类型
        images_reuse_filename: true,
        skin_url: '/tinymace/skins/ui/oxide',  //皮肤：浅色
        plugins: this.plugins,  //插件配置
        content_css: '/tinymace/skins/content/default/content.css',
        toolbar: this.toolbar,  //工具栏配置，设为false则隐藏
        // menubar: 'file edit',  //菜单栏配置，设为false则隐藏，不配置则默认显示全部菜单，也可自定义配置--查看 http://tinymce.ax-z.cn/configure/editor-appearance.php --搜索“自定义菜单”
        fontsize_formats: '12px 14px 16px 18px 20px 22px 24px 28px 32px 36px 48px 56px 72px',  //字体大小
        font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;',
        height: 800,  //注：引入autoresize插件时，此属性失效
        placeholder: '在这里输入文字',
        branding: false,  //tiny技术支持信息是否显示
        resize: false,  //编辑器宽高是否可变，false-否,true-高可变，'both'-宽高均可，注意引号
        statusbar: true,  //最下方的元素路径和字数统计那一栏是否显示
        elementpath: false,  //元素路径是否显示
        content_style: "img {max-width:100%;}",  //直接自定义可编辑区域的css样式
        // 新加
        paste_data_images: true,
        // 结束
        // content_css: '/tinycontent.css',  //以css文件方式自定义可编辑区域的css样式，css文件需自己创建并引入
        images_upload_handler: (blobInfo, success, failFun) => {
          //   const img = 'data:image/jpeg;base64,' + blobInfo.base64()
          //   success(img)
          var xhr, formData;
          var file = blobInfo.blob();//转化为易于理解的file对象
          xhr = new XMLHttpRequest();
          xhr.withCredentials = false;
          let host = process.env.VUE_APP_BASE_API;
          xhr.open('POST', host + '/upload/file/true');
          xhr.onload = function () {
            var json;
            if (xhr.status != 200) {
              failFun('HTTP Error: ' + xhr.status);
              return;
            }
            json = JSON.parse(xhr.responseText);
            if (!json || typeof json.location != 'string') {
              let url = json.data.url;
              success(url);
              return;
            }
          };
          formData = new FormData();
          formData.append('file', file, file.name);//此处与源文档不一样
          xhr.send(formData);
        },

      },
      contentValue: this.value,


    }
  },
  created () { tinymce.init({}); },
  mounted () {

  },
  watch: {
    value (newValue) {
      this.contentValue = newValue;
      tinymce.activeEditor.setContent(newValue);
    },
    contentValue (newValue) {
      this.$emit('input', newValue);
      
    }
  },
  methods: {

    //   重新绘制富文本编辑器
    initTiny (v) {
      tinymce.activeEditor.setContent(v);
      tinymce.init({});
    },
    // 需要什么事件可以自己增加
    onClick (e) {
      this.$emit('onClick', e, tinymce)
    },
    //   获取文本
    getText () {
      let text = tinymce.activeEditor.getContent();
      return text;
    }
  },
}
</script>

<style lang="scss">
.tinymce-box {
}
</style>