import {
    createRouter,
    createWebHistory
} from "vue-router";
import Home from "../views/Home.vue";
import store from '../store/index'
import {
    menuList,
    clients
} from "../api/index";
import {
    ElNotification
} from 'element-plus'

const routes = [{
    path: '/',
    redirect: '/dashboard'
}, {
    path: "/",
    name: "Home",
    component: Home,
    children: [{
        path: "/dashboard",
        name: "dashboard",
        meta: {
            title: '系统首页'
        },
        component: () => import(
            /* webpackChunkName: "dashboard" */
            "../views/Dashboard.vue")
    },
    {
        path: '/modelTextList',
        name: 'ModelTextList',
        meta: {
            title: '总结范文审核'
        },
        component: () => import(
            /* webpackChunkName: "table" */
            "../views/ppt/ModelTextList.vue"
        )

    },

    {
        path: "/table",
        name: "basetable",
        meta: {
            title: '文档管理'
        },
        component: () => import(
            /* webpackChunkName: "table" */
            "../views/ppt/BaseTable.vue")
    },
    {
        path: "/types",
        name: "types",
        meta: {
            title: '分类管理'
        },
        component: () => import(
            /* webpackChunkName: "table" */
            "../views/ppt/Types.vue")
    }, {
        path: "/similar",
        name: "similar",
        meta: {
            title: '同类管理'
        },
        component: () => import(
            /* webpackChunkName: "table" */
            "../views/ppt/Similar.vue")
    },
    {
        path: "/search",
        name: "search",
        meta: {
            title: '搜索词配置'
        },
        component: () => import(
            /* webpackChunkName: "table" */
            "../views/ppt/Search.vue")
    },
    {
        path: "/operate",
        name: "operate",
        meta: {
            title: '运营位配置'
        },
        component: () => import(
            /* webpackChunkName: "table" */
            "../views/ppt/Operate.vue")
    },
    {
        path: "/payprice",
        name: "payprice",
        meta: {
            title: '支付套餐配置'
        },
        component: () => import(
            /* webpackChunkName: "table" */
            "../views/ppt/PayPrice.vue")
    },
    {
        path: "/specialSet",
        name: "specialSet",
        meta: {
            title: '专题页配置'
        },
        component: () => import(
            /* webpackChunkName: "table" */
            "../views/ppt/SpecialSet.vue")
    },
    {
        path: "/paycount",
        name: "paycount",
        meta: {
            title: '支付套餐配置'
        },
        component: () => import(
            /* webpackChunkName: "table" */
            "../views/ppt/PayCount.vue")
    },
    {
        path: "/downcount",
        name: "downcount",
        meta: {
            title: '支付套餐配置'
        },
        component: () => import(
            /* webpackChunkName: "table" */
            "../views/ppt/DownCount.vue")
    },
    {
        path: "/reportForm",
        name: "reportForm",
        meta: {
            title: '报表统计'
        },
        component: () => import(
            /* webpackChunkName: "table" */
            "../views/ppt/ReportForm.vue")
    },
    {
        path: "/adddoc",
        name: "adddoc",
        meta: {
            title: '添加文档'
        },
        component: () => import(
            /* webpackChunkName: "table" */
            "../views/ppt/AddDoc.vue")
    }, {
        path: "/changedoc",
        name: "changedoc",
        meta: {
            title: '修改文档'
        },
        component: () => import(
            /* webpackChunkName: "table" */
            "../views/ppt/ChangeDoc.vue")
    },
    {
        path: "/audit",
        name: "audit",
        meta: {
            title: '审核管理'
        },
        component: () => import(
            "../views/ppt/Audit.vue")
    },

    {
        path: "/charts",
        name: "basecharts",
        meta: {
            title: '图表'
        },
        component: () => import(
            /* webpackChunkName: "charts" */
            "../views/BaseCharts.vue")
    }, {
        path: "/form",
        name: "baseform",
        meta: {
            title: '表单'
        },
        component: () => import(
            /* webpackChunkName: "form" */
            "../views/BaseForm.vue")
    },
    // 秒会开始
    {
        path: "/course",
        name: "course",
        meta: {
            title: '课程管理'
        },
        component: () => import(
            "../views/miaohui/course.vue")
    },
    {
        path: "/member",
        name: "member",
        meta: {
            title: '会员管理'
        },
        component: () => import(
            "../views/miaohui/member.vue")
    },
    {
        path: "/member",
        name: "member",
        meta: {
            title: '会员管理'
        },
        component: () => import(
            "../views/miaohui/member.vue")
    },
    {
        path: "/occupation",
        name: "occupation",
        meta: {
            title: '职业管理'
        },
        component: () => import(
            "../views/miaohui/occupation.vue")
    },
    {
        path: "/position",
        name: "position",
        meta: {
            title: '职位管理'
        },
        component: () => import(
            "../views/miaohui/position.vue")
    },
    {
        path: "/scene",
        name: "scene",
        meta: {
            title: '场景管理'
        },
        component: () => import(
            "../views/miaohui/scene.vue")
    },
    {
        path: "/trade",
        name: "trade",
        meta: {
            title: '行业管理'
        },
        component: () => import(
            "../views/miaohui/trade.vue")
    },
    {
        path: "/repair",
        name: "repair",
        meta: {
            title: '远程维修价格配置'
        },
        component: () => import(
            "../views/miaohui/repair.vue")
    },
    {
        path: "/docEadit",
        name: "docEadit",
        meta: {
            title: '文档编辑'
        },
        component: () => import(
            "../views/miaohui/docEadit.vue")
    },
    {
        path: "/order",
        name: "orderList",
        meta: {
            title: '订单管理'
        },
        component: () => import(
            "../views/miaohui/orderList.vue")
    }, {
        path: "/bdcount",
        name: "bdcount",
        meta: {
            title: '百度安装统计'
        },
        component: () => import(
            "../views/miaohui/baiduCount.vue")
    },
    {
        path: "/user",
        name: "user",
        meta: {
            title: '用户管理'
        },
        component: () => import(
            "../views/miaohui/user.vue")
    },

    // 秒会结束
    // 微信多开--开始
    {
        path: "/packageConfig",
        name: "packageConfig",
        meta: {
            title: '套餐配置'
        },
        component: () => import(
            "../views/moreWx/packageConfig.vue")
    },
    {
        path: "/wxOrder",
        name: "wxOrder",
        meta: {
            title: '订单管理'
        },
        component: () => import(
            "../views/moreWx/wxOrder.vue")
    },
    {
        path: "/operationCount",
        name: "operationCount",
        meta: {
            title: '操作统计'
        },
        component: () => import(
            "../views/moreWx/operationCount.vue")
    },
    // 微信多开--结束


    {
        path: "/tabs",
        name: "tabs",
        meta: {
            title: 'tab标签'
        },
        component: () => import(
            /* webpackChunkName: "tabs" */
            "../views/Tabs.vue")
    }, {
        path: "/donate",
        name: "donate",
        meta: {
            title: '鼓励作者'
        },
        component: () => import(
            /* webpackChunkName: "donate" */
            "../views/Donate.vue")
    }, {
        path: "/permission",
        name: "permission",
        meta: {
            title: '权限管理',
            permission: true
        },
        component: () => import(
            /* webpackChunkName: "permission" */
            "../views/Permission.vue")
    }, {
        path: "/i18n",
        name: "i18n",
        meta: {
            title: '国际化语言'
        },
        component: () => import(
            /* webpackChunkName: "i18n" */
            "../views/I18n.vue")
    }, {
        path: "/upload",
        name: "upload",
        meta: {
            title: '上传插件'
        },
        component: () => import(
            /* webpackChunkName: "upload" */
            "../views/Upload.vue")
    }, {
        path: "/icon",
        name: "icon",
        meta: {
            title: '自定义图标'
        },
        component: () => import(
            /* webpackChunkName: "icon" */
            "../views/Icon.vue")
    }, {
        path: '/404',
        name: '404',
        meta: {
            title: '找不到页面'
        },
        component: () => import( /* webpackChunkName: "404" */
            '../views/404.vue')
    }, {
        path: '/403',
        name: '403',
        meta: {
            title: '没有权限'
        },
        component: () => import( /* webpackChunkName: "403" */
            '../views/403.vue')
    },
    {
        path: "/product",
        name: "product",
        meta: {
            title: '支付中心'
        },
        component: () => import(
            "../views/pay/product.vue")
    },
    // SEO 模块**********************/
    {
        path: "/label",
        name: "label",
        meta: {
            title: '标签管理'
        },
        component: () => import(
            "../views/seo/tag.vue")
    },
    {
        path: "/type",
        name: "type",
        meta: {
            title: '类型管理'
        },
        component: () => import(
            "../views/seo/type.vue")
    },
    {
        path: "/doc",
        name: "doc",
        meta: {
            title: '文章管理'
        },
        component: () => import(
            "../views/seo/doc.vue")
    },
    {
        path: "/docEdit",
        name: "docEdit",
        meta: {
            title: '文章添加/编辑'
        },
        component: () => import(
            "../views/seo/docEdit.vue")
    },
    {
        path: "/url",
        name: "url",
        meta: {
            title: '友链管理'
        },
        component: () => import(
            "../views/seo/url.vue")
    },
    {
        path: "/browserDesktop",
        name: "browserDesktop",
        meta: {
            title: '浏览器桌面推送'
        },
        component: () => import(
            "../views/pushMessage/browserDesktop.vue")
    },
    {
        path: "/pc",
        name: "pc",
        meta: {
            title: '客户端弹窗'
        },
        component: () => import(
            "../views/pushMessage/pc.vue")
    },{
        path: "/pcDesktop",
        name: "pcDesktop",
        meta: {
            title: '客户端桌面推送'
        },
        component: () => import(
            "../views/pushMessage/pcDesktop.vue")
    },{
        path: "/pcAdd",
        name: "pcAdd",
        meta: {
            title: '客户端弹窗添加'
        },
        component: () => import(
            "../views/pushMessage/pcAdd.vue")
    },{
        path: "/pcEdit",
        name: "pcEdit",
        meta: {
            title: '客户端弹窗修改'
        },
        component: () => import(
            "../views/pushMessage/pcAdd.vue")
    },{
        path: "/pcDesktopAdd",
        name: "pcDesktopAdd",
        meta: {
            title: '客户端桌面推送添加'
        },
        component: () => import(
            "../views/pushMessage/pcDesktopAdd.vue")
    },{
        path: "/pcDesktopEdit",
        name: "pcDesktopEdit",
        meta: {
            title: '客户端桌面推送修改'
        },
        component: () => import(
            "../views/pushMessage/pcDesktopAdd.vue")
    },

    ]
}, {
    path: "/login",
    name: "Login",
    meta: {
        title: '登录'
    },
    component: () => import(
        /* webpackChunkName: "login" */
        "../views/Login.vue")
}];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async (to, from, next) => {
    document.title = `${to.meta.title} | vue-manage-system`;
    const role = JSON.parse(localStorage.getItem('localData'));
    console.log('role', role);
    if (!role && to.path !== '/login') {
        console.log('login----');
        localStorage.removeItem('localData')
        next({
            path: '/login',
            query: {
                fullPath: to.fullPath
            }
        });
    } else if (to.meta.permission) {
        // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
        role === 'admin' ?
            next() :
            next('/403');
    } else {
        if (to.path !== '/login') { //已登录，获取菜单
            try {
                let result = await clients(); //获取客户端信息
                let index = localStorage.getItem('index') || 0
                if (result.code == 0) {
                    console.log(result);
                    if (result.data.length > 0) {
                        let data = result.data;
                        store.commit("setClientsList", data);
                        let systemId = data[index].systemId
                        let res = await menuList({
                            systemId
                        }) // 获取菜单
                        if (res.code == 0) {
                            let list = res.data
                            store.commit("setMmenuList", list);
                            store.commit("setIsFirst", true);
                            next();
                        } else { //获取菜单失败
                            ElNotification({
                                type: 'error',
                                title: '提示',
                                message: res.message || '获取菜单失败'
                            })
                        }
                        console.log('res', res);
                    } else { //没有客户端信息数据
                        ElNotification({
                            type: 'error',
                            title: '提示',
                            message: '获取菜单失败'
                        })
                    }
                } else {
                    ElNotification({
                        type: 'error',
                        title: '提示',
                        message: '获取clients信息失败'
                    })
                }
            } catch (error) {
                ElNotification({
                    type: 'error',
                    title: '提示',
                    message: '获取clients信息失败'
                })

            }
        } else {
            next();

        }

    }
});

export default router;