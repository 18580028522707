import router from "./router";

router.beforeEach((to, from, next) => {
	console.log(to, from, );
	let flag = 1;
	if (to.path == '/login') {
		next()
	} else {
		if (flag > 0) {
			next();
		} else {
			next({
				name: 'Login'
			});
		}

	}

});