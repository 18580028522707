<template>
  <div class="sidebar">
    <el-menu class="sidebar-el-menu"
             :default-active="onRoutes"
             :collapse="collapse"
             background-color="#324157"
             text-color="#bfcbd9"
             active-text-color="#20a0ff"
             :unique-opened='uniqueOpened'
             :router="routerFlag">
      <template v-for="(item, inx) in items">
        <template v-if="item.child">
          <el-submenu :index="item.code"
                      :key="inx">
            <template #title>
              <i :class="item.icon"></i>
              <span>{{ item.name }}</span>
            </template>
            <template v-for="subItem in item.child">
              <el-submenu v-if="subItem.child.length > 0"
                          :index="item.code"
                          :key="subItem.path">
                <template #title>{{ subItem.name }}</template>
                <el-menu-item v-for="(threeItem, i) in subItem.child.length > 0"
                              :key="i"
                              :index="item.code">{{ threeItem.name }}</el-menu-item>
              </el-submenu>
              <el-menu-item v-else
                            :index="subItem.path"
                            :key="subItem.path">{{
                subItem.name
              }}</el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.path"
                        :key="item.index">
            <i :class="item.icon"></i>
            <template #title>{{ item.name }}</template>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
// import bus from "../common/bus";

export default {
  data () {
    return {
      routerFlag: true,
      uniqueOpened: true,
    };
  },
  created () {
  },
  methods: {
  },
  computed: {
    items () {
      return this.$store.state.menuList
    },
    onRoutes () {
      // console.log('this.$route.path',this.$route.path);
      return this.$route.path;
    },
    collapse () {
      return this.$store.state.collapse;
    }
  }
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}
.sidebar > ul {
  height: 100%;
}
</style>
