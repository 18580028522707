const saBs = {} // 基础功能

// 全局参数
saBs.$version = '1.0.0'
saBs.$apiServer = {
    baseApiLogin: process.env.VUE_APP_BASE_APILogin,
    baseApi: process.env.VUE_APP_BASE_API,
    miaoHuiApi: process.env.VUE_APP_BASE_MIAOHUI,
    pptRefresh: process.env.VUE_APP_REFRESH,
    refund: process.env.VUE_APP_BASE_REFUND, // 退款
    payCanter: process.env.VUE_APP_BASE_PAYCENTER // 支付中心
    
}
saBs.$api = {
    login: `${saBs.$apiServer.baseApiLogin}/oauth/token`, // 登录
    docList: `${saBs.$apiServer.baseApi}/document/list`, // 文档管理
    getSearchWords: `${saBs.$apiServer.baseApi}/ppt/admin/index/searchWords`, //获取搜索词、热门搜索词、搜索词排行
    updateSearchWords: `${saBs.$apiServer.baseApi}/ppt/admin/index/updateSearchWords`, //更新搜索词、热门搜索词、搜索词排行
    bannerList: `${saBs.$apiServer.baseApi}/ppt/admin/index/images/list`, //首页轮播图列表
    updateBanner: `${saBs.$apiServer.baseApi}/ppt/admin/index/images/update`, //首页轮播图片更新、新增
    docDesc: `${saBs.$apiServer.baseApi}/document/`, // 文档详情
    menuList: `${saBs.$apiServer.baseApiLogin}/menu/list`, //菜单
    clients: `${saBs.$apiServer.baseApiLogin}/user/systems`, //获取用户下的客户端信息
    upload: `${saBs.$apiServer.baseApi}/upload/file/true`, //上传图片
    uploadPPT: `${saBs.$apiServer.baseApi}/upload/doc/`, //上传PPT
    pptDataSave: `${saBs.$apiServer.baseApi}/document/save`, //文档保存
    docTypes: `${saBs.$apiServer.baseApi}/ppt/admin/category/list`, //文档类型
    pptDocCategory: `${saBs.$apiServer.baseApi}/ppt/admin/category/`, // 当前文档的分类
    changeCategory: `${saBs.$apiServer.baseApi}/ppt/admin/category/update`, // 增加修改分类
    categoryStatus: `${saBs.$apiServer.baseApi}/ppt/admin/category/batch`, // 启用禁用 删除
    onlyChangeStatus: `${saBs.$apiServer.baseApi}/document/set/`, // 单个ppt文档启用禁用
    MultipleChangeStatus: `${saBs.$apiServer.baseApi}/document/set`, // 多个ppt文档启用禁用
    SonCategory: `${saBs.$apiServer.baseApi}/ppt/admin/category/child/`, // 获取子类
    FatherCategory: `${saBs.$apiServer.baseApi}/ppt/admin/category/getParent`, // 获取父类
    uploadPdfVideo: `${saBs.$apiServer.baseApi}/upload/file/uploadType/`, //上传Pdf 视频
    topPicList: `${saBs.$apiServer.baseApi}/ppt/admin/topic/list`, // 专题页配置-列表
    topPicDesc: `${saBs.$apiServer.baseApi}/ppt/admin/topic/getById/`, // 专题详情
    topPicDelete: `${saBs.$apiServer.baseApi}/ppt/admin/topic/del/`, // 专题-删除
    topPicAdd: `${saBs.$apiServer.baseApi}/ppt/admin/topic/update`, // 专题-新增修改，
    DocType: `${saBs.$apiServer.baseApi}/ppt/admin/category/child`, // 获取文档类型分类
    floorData: `${saBs.$apiServer.baseApi}/ppt/admin/topic/getById/`, // 楼层数据
    getHotWord: `${saBs.$apiServer.baseApi}/ppt/admin/index/searchWords`, // 获取热搜词
    setHotWord: `${saBs.$apiServer.baseApi}/ppt/admin/index/modifySearchWords`, // 设置热搜词
    ChangeTypes: `${saBs.$apiServer.baseApi}/ppt/admin/enumValue/update`, //枚举类型添加
    typeList: `${saBs.$apiServer.baseApi}/ppt/admin/enumValue/list`, //  获取枚举列表
    typeStatus: `${saBs.$apiServer.baseApi}/ppt/admin/enumValue/disableOrNot`, // 禁用启用
    tradeList: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/industry/list`, // 行业列表
    tradeDataChange: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/industry/update`, // 新增修改行业
    tradeStatus: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/industry/option/`, // 启用禁用
    occList: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/occupation/list`, // 职位列表
    occAddItem: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/occupation/update`, // 添加 修改职位
    occStatus: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/occupation/option/`, // 职位启用禁用
    allOccList: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/occupation/option/getAll`, // 获取所有职位
    docArray: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/document/list`, //  文档列表
    docData: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/document/getById/`, // 单个文档数据
    docStatus: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/document/option/`, //  启用禁用文档
    docChange: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/document/update`, // 修改文档
    allIndustry: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/industry/getUserfulAll`, // 所有行业
    allOcc: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/occupation/getUserfulAll`, // 所有职业
    allPosition: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/position/getUserfulAll`, // 所有职位
    allPosiList: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/occupation/child`, //  通过职业筛选职位
    senceList: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/scene/list`, // 所有场景列表
    allSenceList: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/scene/getUserfulAll`, //获取所有场景 不分页
    vipList: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/members/list`, // 所有会员类型分类 分页
    allVipList: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/members/getAll`, // 获取所有vip类型 不分页
    uploadMh: `${saBs.$apiServer.baseApi}/upload/file/true`, //上传图片
    senceArray: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/scene/list`, // 场景列表
    addSenceList: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/scene/update`, // 新增 修改场景
    senceStatus: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/scene/option/`, //  禁用启用场景
    senceItem: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/scene/getById/`, // 获取单个数据
    allPositionTwo: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/occupation/getAll`, // 获取所有职业
    positionListTwo: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/position/list`, // 职位列表
    postitionUpdate: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/position/update`, // 职位添加 修改
    positionStatus: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/position/option/`, // 职位启用， 禁用
    jfList: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/points/list`, // 积分列表
    jfChange: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/points/update`, //  新增修改积分
    jfStatus: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/points/disableOrNot/`, // 积分 启用禁用 
    memberList: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/members/list`, //  会员列表 
    memberStatus: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/members/disableOrNot/`, //  会员启用禁用
    memberChange: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/members/update`, //  vip会员新增修改
    dayTimer: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/members/getDaily`, // 下拉选择天数
    orderList: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/order/list`, // 订单列表
    channelList: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/order/getChannelList`,// 渠道列表-- 秒会
    downExcel: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/order/export`,
    baiDuCount: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/members/getUserDailyStastics`,
    refreshData: `${saBs.$apiServer.pptRefresh}/pptsearcher/rebuildindex`, // 编辑文章之后立即刷新，
    modelTextList: `${saBs.$apiServer.baseApi}/documentdraft/list`, // 总结范文审核列表

    clientConfig: `${saBs.$apiServer.miaoHuiApi}/user/clientConfig`,//获取渠道列表
    startCheck: `${saBs.$apiServer.baseApi}/documentdraft/startCheck`, // 开始审核
    documentdraftSave: `${saBs.$apiServer.baseApi}/documentdraft/save`, // 开始审核
    channelDay: `${saBs.$apiServer.baseApi}/ppt/admin/statistics/channelDay`, // 渠道统计 
    totalDay: `${saBs.$apiServer.baseApi}/ppt/admin/statistics/totalDay`, // 总表统计 
    actualTime: `${saBs.$apiServer.baseApi}/ppt/admin/statistics/actualTime`, // 实时统计 
    // 同类管理
    pptTypeList: `${saBs.$apiServer.baseApi}/ppt/admin/sameCategory/list`, // 列表
    addTypeList: `${saBs.$apiServer.baseApi}/ppt/admin/sameCategory/update`, //  添加同类
    oneType: `${saBs.$apiServer.baseApi}/ppt/admin/sameCategory/`, // 获取单个同类
    oneCateData: `${saBs.$apiServer.baseApi}/ppt/admin/category/`, // 获取单个ppt子分类
    // 退款 user/lenove/getUserInfo
    refund: `${saBs.$apiServer.refund}/uc/order/refund`, // 退款获取联想token
    // 远程刷机
    exportShuajiOrder: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/order/exportShuajiOrder`, // 刷机远程维修订单导出
    findShuajiProduct: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/members/findShuajiProduct`, // 查看远程维修价格
    saveShuajiProduct: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/members/saveShuajiProduct`, // 编辑远程维修价格
    userList: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/members/userList`, // 用户列表
    getCombo: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/members/getCombo`, // 获取套餐
    createAdminPay: `${saBs.$apiServer.refund}/uc/order/createAdminPay`, // 给用户加套餐
    optionUserStatus: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/members/optionUserStatus/`, // 用户状态修改
    getAllProducts: `${saBs.$apiServer.payCanter}/payment/airwallex/getAllProducts`, // 支付中心列表
    createProduct: `${saBs.$apiServer.payCanter}/payment/airwallex/saveProduct`, // 支付创建
    updateProduct: `${saBs.$apiServer.payCanter}/payment/airwallex/updateProduct`, // 支付修改
    modifyProduct: `${saBs.$apiServer.payCanter}/payment/airwallex/modifyProduct`, // 支付修改
    operateProduct: `${saBs.$apiServer.payCanter}/payment/airwallex/operateProduct`, // 状态修改
    seoLabelList: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/label/list`, // SEO 获取标签列表
    seoLabelUpdate: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/label/update`, // SEO新增修改标签
    seoLabelOption: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/label/option`, // SEO修改单个状态
    seoLabelBatch: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/label/batch`, // SEO 批量修改状态
    seoCategoryList: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/category/list`, // SEO 获取类型列表
    seoCategoryUpdate: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/category/update`, // SEO新增修改类型
    seoCategoryOption: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/category/option`, // SEO修改单个状态
    seoCategoryBatch: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/category/batch`,
    seoArticleList: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/article/list`, // SEO 获取类型列表
    seoArticleUpdate: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/article/update`, // SEO新增修改类型
    seoArticleOption: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/article/option`, // SEO修改单个状态
    seoArticleBatch: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/article/batch`, // SEO 批量修改状态
    seoArticleGetById: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/article/getById`,
    seoLinksList: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/links/list`, // SEO修改单个状态
    seoLinksUpdate: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/links/update`, // SEO 批量修改状态
    seoLinksDeleteById: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/links/deleteById`,
    // 推送
    pcUpdate: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/message/pc/update`,
    pcList: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/message/pc/list`,
    pcGetById: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/message/pc/getById`,
    pcOption: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/message/pc/option`,
    modifyMember: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/members/modifyMember`,
    getMember: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/members/getMember/`,
    getLanguage: `${saBs.$apiServer.miaoHuiApi}/miaohui/admin/category/getLanguage`,

}
export {
    saBs
}