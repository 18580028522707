import {
    get,
    post,
    postJson
} from '../utils/request.js'
import {
    saBs
} from './base.js'

function login (params) {
    return post(saBs.$api.login, params)
}

function fetchData (params) {
    return get('./table.json', params)
}
// 文档管理列表
function docManageList (params) {
    return get(saBs.$api.docList, params)
}

function getSearchWords (params) {
    return get(saBs.$api.getSearchWords, params)
}
function userList (params) {
    return postJson(saBs.$api.userList, params)
}
function getCombo (params) {
    return postJson(saBs.$api.getCombo, params)
}
function optionUserStatus (id) {
    return postJson(saBs.$api.optionUserStatus + id)
}

function updateSearchWords (params) {
    return postJson(saBs.$api.updateSearchWords, params)
}

function getBannerList (params) {
    return get(saBs.$api.bannerList, params)
}
// 文档详情
function getDocDesc (id, params) {
    return get(saBs.$api.docDesc + id, params)
}

function getUpload (params) {
    return postJson(saBs.$api.upload, params)
}
// 秒会上传
function getUploadMh (params) {
    return postJson(saBs.$api.uploadMh, params)
}

function updateBanner (params) {
    return postJson(saBs.$api.updateBanner, params)
}

function getUploadPPT (id, params) {
    return postJson(saBs.$api.uploadPPT + id, params)
}

function getPptDataSave (params) {
    return postJson(saBs.$api.pptDataSave, params)

}

function getDocTypes (params) {
    return postJson(saBs.$api.docTypes, params)

}


function getDocCategory (id, params) {
    return get(saBs.$api.pptDocCategory + id, params)

}

function getChangeCategory (params) {
    return postJson(saBs.$api.changeCategory, params)

}

function getCategoryStatus (params) {
    return postJson(saBs.$api.categoryStatus, params)

}



function menuList (params) {
    return post(saBs.$api.menuList + '/' + params.systemId, params)
}

function clients (params) {
    return get(saBs.$api.clients, params)
}

function changeOnlyStatus (params) {
    return postJson(saBs.$api.onlyChangeStatus + params.id + '/' + params.enable)
}

function changeMoreStatus (params) {
    return postJson(saBs.$api.MultipleChangeStatus, params)

}

function getSonCategory (params) {
    return get(saBs.$api.SonCategory + params,)
}

function getFatherCategory (params) {
    return get(saBs.$api.FatherCategory, params)
}

function getUpLoadPdfVideo (uploadType, params) {
    return postJson(saBs.$api.uploadPdfVideo + uploadType, params) // 3:PDF   4:视频
}

function getTopPicList (params) {
    return postJson(saBs.$api.topPicList, params)
}

function getTopPicDesc (id, params) {
    return get(saBs.$api.topPicDesc + id, params)
}

function getTopPicDelete (id, params) {
    return postJson(saBs.$api.topPicDelete + id, params)
}

function getTopPicAdd (params) {
    return postJson(saBs.$api.topPicAdd, params)
}

function getDocType (params) {
    return postJson(saBs.$api.DocType, params)
}

function getFloorData (id, params) {
    return get(saBs.$api.floorData + id, params)
}


function getHotWordList (params) {
    return get(saBs.$api.getHotWord, params)
}


function setHotWordList (params) {
    return postJson(saBs.$api.setHotWord, params)
}
// 枚举类型添加，修改
function getChangeTypes (params) {
    return postJson(saBs.$api.ChangeTypes, params)
}

function getTypeList (params) {
    return postJson(saBs.$api.typeList, params)
}

function getTypeStatus (params) {
    return postJson(saBs.$api.typeStatus, params)
}

function getPayList (params) {
    let url = "http://192.168.16.79:9003/product/pptmemberedit";
    return get(url, params)
}

function savePayList (params) {
    let url = "http://192.168.16.79:9003/product/pptmemberedit";
    return postJson(url, params)
}

function getTradeList (params) {
    return postJson(saBs.$api.tradeList, params)
}

function getTradeDataChange (params) {
    return postJson(saBs.$api.tradeDataChange, params)
}

function getTradeStatus (params) {
    return get(saBs.$api.tradeStatus + params.id, params)
}

function getOccList (params) {
    return postJson(saBs.$api.occList, params)
}

function getOccAddItem (params) {
    return postJson(saBs.$api.occAddItem, params)
}

function getOccStatus (params) {
    return get(saBs.$api.occStatus + params.id)
}

function getAllOccList (params) {
    return get(saBs.$api.allOccList, params)
}
// 文档列表
function getDocArray (params) {
    return postJson(saBs.$api.docArray, params)
}
// 添加 修改文档
function getDocChange (params) {
    return postJson(saBs.$api.docChange, params)
}
// 获取单个文档
function getDocData (params) {
    return get(saBs.$api.docData + params.id)
}
// 启用 禁用 文档
function getDocStatus (params) {
    return get(saBs.$api.docStatus + params.id)
}

function getAllIndustry () {
    return get(saBs.$api.allIndustry)

}

function getAllOcc () {
    return get(saBs.$api.allOcc)

}

function getAllPosition () {
    return get(saBs.$api.allPosition)

}

function GetAllPosiList (params) {
    return postJson(saBs.$api.allPosiList, params)
}
// 所有场景列表
function getSenceList (params) {
    return postJson(saBs.$api.senceList, params)
}

function getAllSenceList (params) {
    return get(saBs.$api.allSenceList, params)
}
// 所有会员类型
function getVipList (params) {
    return postJson(saBs.$api.vipList, params)
}

function getAllVipList (params) {
    return get(saBs.$api.allVipList, params)

}

function getSenceArray (params) {
    return postJson(saBs.$api.senceArray, params)
}

function getAddSenceList (params) {
    return postJson(saBs.$api.addSenceList, params)
}

function getSenceStatus (params) {
    return get(saBs.$api.senceStatus + params.id, params)
}

function getSenceItem (params) {
    return get(saBs.$api.senceItem + params.id, params)
}

function getAllPositionTwo (params) {
    return get(saBs.$api.allPositionTwo, params)
}

function getPositionListTwo (params) {
    return postJson(saBs.$api.positionListTwo, params)

}

function getPostitionUpdate (params) {
    return postJson(saBs.$api.postitionUpdate, params)
}

function getPositionStatus (params) {
    return get(saBs.$api.positionStatus + params.id)

}

function getJfList (params) {
    return postJson(saBs.$api.jfList, params)

}

function getJfChange (params) {
    return postJson(saBs.$api.jfChange, params)

}

function getJfStatus (params) {
    return postJson(saBs.$api.jfStatus + params.id, params)
}

function getMemberList (params) {
    return postJson(saBs.$api.memberList, params)

}

function getMemberStatus (params) {
    return postJson(saBs.$api.memberStatus + params.id)
}

function getMemberChange (params) {
    return postJson(saBs.$api.memberChange, params)
}

function getDayTimer (params) {
    return postJson(saBs.$api.dayTimer, params)
}

function getOrderList (params) {
    return postJson(saBs.$api.orderList, params)
}
function getChannelList () {
    return get(saBs.$api.channelList)
}

function getDownExcel (params) {
    return get(saBs.$api.downExcel, params)
}

function getRefreshData (params) {
    return postJson(saBs.$api.refreshData, params)
}

function getModelTextList (params) {
    return get(saBs.$api.modelTextList, params)
}



function startCheck (params) {
    return postJson(saBs.$api.startCheck, params)
}

function documentdraftSave (params) {
    return postJson(saBs.$api.documentdraftSave, params)
}
// 渠道统计
function getChannelDay (params) {
    return postJson(saBs.$api.channelDay, params)
}
// 总表统计
function getTotalDay (params) {
    return postJson(saBs.$api.totalDay, params)
}
// 当日统计
function getActualTime (params) {
    return postJson(saBs.$api.actualTime, params)
}

function getpptTypeList (params) {
    return postJson(saBs.$api.pptTypeList, params)
}

function getAddTypeList (params) {
    return postJson(saBs.$api.addTypeList, params)
}

function getoneType (params) {
    return get(saBs.$api.oneType + params.id, params)
}

function getOneCateData (params) {
    return get(saBs.$api.oneCateData + params.id)
}


function clientConfig (params) {
    return get(saBs.$api.clientConfig, params)
}
function getLanguage (params) {
    return postJson(saBs.$api.getLanguage, params)
}

function saveShuajiProduct (params) {
    return postJson(saBs.$api.saveShuajiProduct, params)
}
function findShuajiProduct (params) {
    return get(saBs.$api.findShuajiProduct, params)
}
function exportShuajiOrder (params) {
    return postJson(saBs.$api.exportShuajiOrder + `?endTime=${params.endTime}&startTime=${params.startTime}`)
}

function refund (params) {
    return postJson(saBs.$api.refund, params)
}
function createAdminPay (params) {
    return postJson(saBs.$api.createAdminPay, params)
}
function getBaiDuCount (params) {
    return postJson(saBs.$api.baiDuCount, params)
}
function getAllProducts (params) {
    return postJson(saBs.$api.getAllProducts, params)
}
function createProduct (params) {
    return postJson(saBs.$api.createProduct, params)
}
function modifyProduct (params) {
    return postJson(saBs.$api.modifyProduct, params)
}

function operateProduct (params) {
    return postJson(saBs.$api.operateProduct, params)
}
function seoLabelList (params) {
    return postJson(saBs.$api.seoLabelList, params)
}
function seoLabelUpdate (params) {
    return postJson(saBs.$api.seoLabelUpdate, params)
}
function seoLabelOption (params) {
    return get(saBs.$api.seoLabelOption + '/' + params)
}

function seoLabelBatch (params) {
    return postJson(saBs.$api.seoLabelBatch, params)
}


function seoCategoryList (params) {
    return postJson(saBs.$api.seoCategoryList, params)
}
function seoCategoryUpdate (params) {
    return postJson(saBs.$api.seoCategoryUpdate, params)
}
function seoCategoryOption (params) {
    return get(saBs.$api.seoCategoryOption + '/' + params)
}

function seoCategoryBatch (params) {
    return postJson(saBs.$api.seoCategoryBatch, params)
}

function seoArticleList (params) {
    return postJson(saBs.$api.seoArticleList, params)
}
function seoArticleUpdate (params) {
    return postJson(saBs.$api.seoArticleUpdate, params)
}
function seoArticleOption (params) {
    return get(saBs.$api.seoArticleOption + '/' + params)
}

function seoArticleBatch (params) {
    return postJson(saBs.$api.seoArticleBatch, params)
}
function seoArticleGetById (params) {
    return get(saBs.$api.seoArticleGetById + '/' + params)
}

function seoLinksList (params) {
    return postJson(saBs.$api.seoLinksList, params)
}
function seoLinksUpdate (params) {
    return postJson(saBs.$api.seoLinksUpdate, params)
}

function seoLinksDeleteById (params) {
    return get(saBs.$api.seoLinksDeleteById + '/' + params)
}

function pcUpdate (params) {
    return postJson(saBs.$api.pcUpdate, params)
}
function pcList (params) {
    return postJson(saBs.$api.pcList, params)
}
function pcGetById (params) {
    return get(saBs.$api.pcGetById + '/' + params)
}

function pcOption (params) {
    return get(saBs.$api.pcOption + '/' + params)
}

function modifyMember (params) {
    return postJson(saBs.$api.modifyMember, params)
}
function getMember(params) {
    return get(saBs.$api.getMember + params)
}
export {
    getMember,
    modifyMember,
    pcOption,
    pcGetById,
    pcList,
    pcUpdate,
    seoArticleGetById,
    seoLinksUpdate,
    seoLinksList,
    seoLinksDeleteById,
    seoArticleList,
    seoArticleUpdate,
    seoArticleOption,
    seoArticleBatch,
    seoCategoryBatch,
    seoCategoryOption,
    seoCategoryUpdate,
    seoCategoryList,
    seoLabelBatch,
    seoLabelOption,
    seoLabelUpdate,
    seoLabelList,
    operateProduct,
    modifyProduct,
    createProduct,
    getAllProducts,
    saveShuajiProduct,
    findShuajiProduct,
    exportShuajiOrder,
    login,
    fetchData,
    docManageList,
    getSearchWords,
    updateSearchWords,
    getBannerList,
    getDocDesc,
    getUpload,
    getUploadPPT,
    getPptDataSave,
    getDocTypes,
    getDocCategory,
    getChangeCategory,
    getCategoryStatus,
    updateBanner,
    menuList,
    refund,
    clients,
    changeOnlyStatus,
    changeMoreStatus,
    getSonCategory,
    getFatherCategory,
    getUpLoadPdfVideo,
    getTopPicList,
    getTopPicDesc,
    getTopPicDelete,
    getTopPicAdd,
    getDocType,
    getFloorData,
    getHotWordList,
    setHotWordList,
    getChangeTypes,
    getTypeList,
    getTypeStatus,
    getPayList,
    savePayList,
    getTradeList,
    getTradeDataChange,
    getTradeStatus,
    getOccList,
    getOccAddItem,
    getOccStatus,
    getAllOccList,
    getDocArray,
    getDocChange,
    getDocData,
    getDocStatus,
    getAllIndustry,
    getAllOcc,
    getAllPosition,
    GetAllPosiList,
    getSenceList,
    getAllSenceList,
    getVipList,
    getAllVipList,
    getUploadMh,
    getSenceArray,
    getAddSenceList,
    getSenceStatus,
    getSenceItem,
    getAllPositionTwo,
    getPositionListTwo,
    getPostitionUpdate,
    getPositionStatus,
    getJfList,
    getJfChange,
    getJfStatus,
    getMemberList,
    getMemberStatus,
    getMemberChange,
    getDayTimer,
    getOrderList,
    getDownExcel,
    getRefreshData,
    startCheck,
    getModelTextList,
    documentdraftSave,
    getChannelDay,
    getTotalDay,
    getActualTime,
    getpptTypeList,
    getAddTypeList,
    getoneType,
    getOneCateData,
    clientConfig,
    getChannelList,
    userList,
    getCombo,
    createAdminPay,
    getBaiDuCount,
    optionUserStatus,
    getLanguage

}