import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import './assets/css/icon.css'
import './permission'
import VueQuillEditor from 'vue-quill-editor'
// require styles 引入样式
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
// import 'tinymce/skins/ui/oxide/skin.css'
import TEditor from '@/components/eds.vue'



console.log('process.env.VUE_APP_BASE_API', process.env);
const app = createApp(App)
installElementPlus(app)
app.component('TEditor', TEditor)
app
    .use(store)
    .use(router)
    .use(VueQuillEditor)
    .mount('#app')